/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kuchyně"}>
        <SiteHeader />

        <Column className="pb--50 pl--0 pt--80" name={"uvod"}>
          
          <ColumnWrap className="column__flex el--1 pb--40 pl--40 pr--40 pt--60 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--center mt--10 pl--0 pr--0">
              
              <Title className="title-box fs--62 w--300 ls--002 lh--14" style={{"maxWidth":1082}} content={"Kuchnia"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":838}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"5wocu34mzcq"} layout={"l9"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--bottom" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box text-box--right mt--20" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"l7lqr1exf3"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Nadpis"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech. \r\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6y7ebeflbmu"} layout={"l9"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --left el--1 flex--bottom" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":967}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box text-box--left mt--20" style={{"maxWidth":692}} content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --right el--1 mt--80 pt--60 flex--bottom" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":967}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box text-box--right mt--20" style={{"maxWidth":692}} content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--30 pt--80" name={"e4g4g73fjyf"} style={{"marginBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left" style={{"maxWidth":1084}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. "}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"t68g4epio3"}>
          
          <ColumnWrap className="column__flex el--3 mb--80" columns={"3"}>
            
            <ColumnWrapper className="mt--50" style={{"maxWidth":311}}>
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma. \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50" style={{"maxWidth":311}}>
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma. \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50" style={{"maxWidth":311}}>
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma. \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50" style={{"maxWidth":311}}>
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma. \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50" style={{"maxWidth":311}}>
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma. \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50" style={{"maxWidth":311}}>
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma. \n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"katalog-produktów"} style={{"backgroundColor":"var(--color-custom-2)"}} border={null} layout={"l24"}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right mt--0" content={"<span style='color: var(--color-dominant)'>Usiądźmy</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--left mt--0" href={"/pl/kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pl--0 pt--50" name={"stopka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--3 pb--40 pt--40 flex--bottom" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Merbau"}>
              </Title>

              <Text className="text-box text-box--left fs--16" style={{"maxWidth":351}} content={"Produkujemy drewniane meble w centrum Wrocławia z najwyższej jakości naturalnych surowców."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--left" content={"Pańska 18"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"Warszawa, Miasto Warszawa"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"+48 797 811 2X0"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--hvr2 btn-box--sbtn1 btn-box--cColor2 btn-box--filling2 fs--18" href={"#uvod"} content={"<span style=\"color: rgb(255, 255, 255);\">Powrót do góry</span>"}>
              </Button>

              <Text className="text-box text-box--right mt--0" content={"<a href=\"https://saywebpage.com\">Tworzenie stron</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}